:root {
    --primaryFont: 'Poppins', sans-serif;
    /* --primaryFont: 'Montserrat', sans-serif; */
    /* --primaryFont: 'Raleway', sans-serif; */
    background-color: #212121;
}

a {
    cursor: pointer;
    transition: transform 0.5s 0.3s;
    font: inherit;
    color: inherit;
    text-decoration: underline;
}